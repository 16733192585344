// --- external
import { createMachine, assign, actions } from "xstate";
const { sendParent } = actions;

// --- internal
import services from "./services";
import { useFeedback } from "../../feedback";
const { addError, addSuccess } = useFeedback();

// --- utils
import { useTime, useValidationParser, useModelParser } from "../../../utils";
import { useSchema, useUischema } from "./utils";
import { set } from "lodash-es";

// --- types
import type { BillingDetailsContext, BillingDetailsEvent } from "./types";
import { responseCodes } from "../../api";

// --------------------------------------------------------

export default createMachine(
  {
    tsTypes: {} as import("./details.machine.typegen").Typegen0,
    id: "billingDetailsManager",
    predictableActionArguments: true,
    initial: "subscribing",
    context: {
      basket_id: undefined,
      client_id: undefined,
      // ---
      schema: undefined,
      uischema: undefined,
      model: undefined,
      // ---
      dirty: false,
      error: null,
      autoupdate: false,
    } as BillingDetailsContext,
    states: {
      // Subscribe to basket changes and listen for a valid basket client,
      subscribing: {
        always: { target: "available", cond: "hasClient" },
        on: {
          REFRESH: {
            actions: ["refreshContext"],
            cond: "hasChanged",
          },
          SET: {
            actions: ["setModel", "setDirty", "setAutoUpdate"],
          },
          CLEAR: {
            actions: ["clearModel", "clearDirty"],
          },
        },
      },

      available: {
        initial: "loading",
        states: {
          loading: {
            id: "loading",
            entry: ["clearError"],
            invoke: {
              src: "load",
              onDone: {
                target: "checking",
                actions: ["setLookups", "setSchemas"],
              },
              onError: {
                target: "#error",
                actions: ["setError", "setFeedbackError"],
              },
            },
          },
          // ---

          checking: {
            entry: ["clearError"],
            initial: "parsing",
            states: {
              parsing: {
                invoke: {
                  src: "parse",
                  onDone: {
                    target: "validating",
                    actions: ["setParsed", "setSchemas"],
                  },
                },
              },
              validating: {
                invoke: {
                  src: "validate",
                  onDone: [
                    {
                      target: "#valid",
                      cond: "isDirty",
                    },
                    {
                      target: "#complete",
                    },
                  ],
                  onError: {
                    target: "#invalid",
                    actions: ["setError"],
                  },
                },
              },
            },
          },

          valid: {
            id: "valid",
            always: { target: "processing", cond: "shouldUpdate" },

            on: {
              UPDATE: {
                target: "processing",
                cond: "hasBasket",
              },
            },
          },

          invalid: {
            id: "invalid",
          },

          processing: {
            id: "processing",
            entry: ["clearError"],

            invoke: {
              src: "update",
              onDone: {
                target: "processed",
                actions: ["setModel", "clearDirty", "clearAutoUpdate"],
              },
              onError: {
                target: "#error",
                actions: ["setError", "setFeedbackError"],
              },
            },
          },

          processed: {
            id: "processed",
            entry: sendParent((_context, { data }: any) => ({
              type: "REFRESH",
              data,
            })),
            after: {
              wait: {
                target: "#complete",
              },
            },
          },
        },
      },

      // ---
      error: { id: "error" },

      complete: {
        id: "complete",
      },
    },
    on: {
      CLEAR: {
        target: "available.checking",
        actions: ["clearModel", "setDirty"],
      },
      SET: {
        target: "available.checking",
        actions: ["setModel", "setDirty", "setAutoUpdate"],
      },
      REFRESH: {
        target: "available.checking",
        actions: ["refreshContext", "setSchemas"],
        cond: "hasChanged",
      },
    },
  },
  {
    actions: {
      refreshContext: assign(
        (_context: BillingDetailsContext, { data }: any) => {
          return {
            basket_id: data?.id,
            client_id: data?.client_id,
          };
        }
      ),

      setParsed: assign({
        model: (_context, { data }: any) => data.model,
      }),

      setLookups: assign({
        addresses: (_context, { data }: any) => data.addresses,
        companies: (_context, { data }: any) => data.companies,
      }),

      setSchemas: assign({
        schema: context => useSchema(context),
        uischema: context => useUischema(context),
        model: ({ schema, model }) => useModelParser(schema, model),
      }),

      setModel: assign({
        model: ({ schema, model }, { data }) =>
          useModelParser(schema, data || model),
      }),

      clearModel: assign({
        model: undefined,
      }),

      setDirty: assign({
        dirty: true,
      }),

      clearDirty: assign({
        dirty: false,
      }),

      setAutoUpdate: assign({
        autoupdate: (_context: any, { update }: any) => !!update,
      }),

      clearAutoUpdate: assign({
        // @ts-ignore
        autoupdate: false,
      }),

      // ---
      // @ts-ignore
      setFeedbackSuccess: (_context: any, _event: any) => {
        addSuccess("Successfully updated billing details");
      },

      setFeedbackError: ({ error }, _event) => {
        // dont show any unauthorized errors
        if (
          !error ||
          error?.code == responseCodes.Unprocessable_Entity ||
          error?.code == responseCodes.Unauthorized
        )
          return;

        addError({
          title:
            error?.title || "We experienced an error updating billing details",
          copy: error?.message,
          data: error?.data,
        });
      },

      setError: assign({
        error: (_context, { data }: any) => {
          let error = data?.error;
          if (error?.code == responseCodes.Unprocessable_Entity) {
            // lets parse/override our error message and data
            // this is to generate valid json schema validation errors
            error = useValidationParser(error);
          }

          return error || data;
        },
      }),

      clearError: assign({ error: null }),
    },

    guards: {
      isDirty: ({ dirty }, _event) => !!dirty,
      hasBasket: ({ basket_id }, _event) => !!basket_id,
      hasClient: ({ client_id }, _event) => !!client_id,
      hasChanged: ({ client_id, basket_id }, { data }: any) => {
        return basket_id !== data?.id || client_id !== data?.client_id;
      },
      shouldUpdate: ({ autoupdate, client_id, basket_id, model }, _event) => {
        return (
          !!autoupdate && !!basket_id && !!client_id && !!model?.address_id
        );
      },
    },

    delays: {
      // @ts-ignore
      error: () => useTime().ERROR,
      wait: () => useTime().WAIT,
    },

    // @ts-ignore
    services,
  }
);
