// --- external
import { assign } from "xstate";

// --- utils
import { useSchema, useUischema, useModelParser, spawnItem } from "./utils";
import { find, map } from "lodash-es";

// --- types
import type { EmailContext, EmailEvent } from "./types";
import type { ClientListingsEvents, ClientListingsContext } from "../types";
// --------------------------------------------------------

export const ListingActions = {
  add: assign({
    initial: ({ selected, initial }) => selected?.id || initial,
    selected: (
      _context: ClientListingsContext,
      { data }: ClientListingsEvents
    ) => {
      return spawnItem(data); // spawn an actor for the new raw
    },
  }),
  setItems: assign({
    raw: ({ raw }: ClientListingsContext, { data }: ClientListingsEvents) =>
      map(data, item => {
        const found = find(raw, ["id", item.id]);
        if (!found) return spawnItem(item);
        return found;
      }),
    error: null,
  }),
};

export const ItemActions = {
  setMeta: assign({
    // @ts-ignore
    title: ({ model }: EmailContext, _event: EmailEvent) => model?.email,
    description: ({ model }: EmailContext, _event: EmailEvent) =>
      model?.verified ? "Verified" : "Unverified",
  }),
  setSchemas: assign({
    // TODO: schema: (context: EmailContext, _event: EmailEvent) => useSchema(context),
    schema: (_, _event: EmailEvent) => useSchema(),
    // TODO: uischema: (context: EmailContext, _event: EmailEvent) =>
    // TODO: useUischema(context),
    uischema: (_, _event: EmailEvent) => useUischema(),
  }),

  setModel: assign({
    model: ({ schema, model }: any, { data }: any) =>
      useModelParser(schema, data || model),
  }),
};
