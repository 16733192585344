// --- external
import { interpret } from "xstate";
import { waitFor } from "xstate/lib/waitFor";

// --- internal
import basketMachine from "./basket.machine";

// --- utils
import { every, find, get, some, omitBy, isEmpty } from "lodash-es";
import { responseCodes } from "../api";

// --- types
import type { IProductModel } from "../product/types";
// --------------------------------------------------------
// create a global instance of the basket machine
// and a global object to store state
// NB dont automatically start the machine as in order for the inspector to work
// it needs to be started after the inspect service is created, so we only start it when we need it

// @ts-ignore
const service = interpret(basketMachine, { devTools: true });

// --------------------------------------------------------
// methods
// --------------------------------------------------------
const exists = (items = [], mapping: any, context = null) => {
  // @ts-ignore
  context = context ? `${context}.` : "";
  return some(items, item =>
    every(mapping, (value, key) => {
      const itemValue = get(item, `${context}${key}`, get(item, key));
      const matches = itemValue == value;
      return matches;
    })
  );
};

const sendToItem = (itemId: any, type: any, data: any) => {
  const item = find(service.getSnapshot()?.context?.items, ["id", itemId]);
  if (item) {
    item.send({ type, data });
    return Promise.resolve(item);
  } else {
    return Promise.reject({
      message: "Item not found",
      code: responseCodes.Not_Found,
    });
  }
};

export const useBasket = () => {
  return {
    service: service.start(),
    getSnapshot: () => service.getSnapshot(),
    getBasketId: () => service.getSnapshot()?.context?.basket?.id,

    // --- basket functions
    isReady: async () =>
      waitFor(service, state => ["shopping"].some(state.matches), {
        timeout: Infinity, // infinity = no timeout
      }),

    clear: () => service.send({ type: "CLEAR" }),

    checkout: () => service.send({ type: "CHECKOUT" }),

    refresh: (data?: any) => {
      service.send({ type: "REFRESH", data });
      return waitFor(service, state =>
        state.matches("shopping.refreshing.complete")
      );
    },

    // --- item functions

    getItemsSnapshot: () => service.getSnapshot()?.context?.items || [],

    findItem: (mapping: any) =>
      find(service.getSnapshot()?.context?.items, (basketItem: any) =>
        every(mapping, (value, key) => {
          if (key == "id") {
            return basketItem.id == value;
          } else {
            return get(basketItem, `state.context.model.${key}`) == value;
          }
        })
      ),

    itemExists: (mapping: any) =>
      exists(
        service.getSnapshot()?.context?.items,
        mapping,
        // @ts-ignore
        "state.context.model"
      ),

    addItem: async ({
      id,
      product_id,
      quantity,
      term,
      attributes,
      options,
      provision_fields,
    }: IProductModel) => {
      // lets wait for our basket  to be ready for shopping
      return waitFor(service, state => state.matches("shopping")).then(() => {
        // lets add the new product base don the provided config to the basket
        const mapping = omitBy(
          {
            id,
            product_id,
            quantity,
            term,
            attributes,
            options,
            provision_fields,
          },
          isEmpty
        );

        service.send({
          type: "ADD",
          data: mapping,
        });

        // then wait/check for the new product actor to be configured
        // then send the update event to the basket
        return find(
          service.getSnapshot()?.context?.items,
          (basketItem: any) => {
            const isNew = isEmpty(basketItem.state.context?.basket_product);
            return (
              isNew &&
              every(mapping, (value, key) => {
                if (key == "id" && value) {
                  return basketItem.id == value;
                } else {
                  return get(basketItem, `state.context.model.${key}`) == value;
                }
              })
            );
          }
        );
      });
    },

    // --- Item CRUD

    updateItem: async (itemId: any) => {
      return sendToItem(itemId, "UPDATE", { itemId }).then(item => {
        return waitFor(item, state => !state.matches("processing")).then(
          state => {
            if (["error", "available.error"].some(state.matches)) {
              return Promise.reject(state.context.error);
            }
            return Promise.resolve(item);
          }
        );
        // .finally(() => service.send({ type: "REFRESH" }));
      });
    },

    removeItem: async (itemId: any) => {
      return sendToItem(itemId, "REMOVE", { itemId }).then(item => {
        return waitFor(item, state =>
          ["available.complete", "complete", "error"].some(state.matches)
        ).then(state => {
          if (state.matches("error")) {
            return Promise.reject(state.context.error);
          }
          return Promise.resolve(item);
        });
        // .finally(() => service.send({ type: "REFRESH" }));
      });
    },
  };
};
