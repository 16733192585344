//  --- external
import { spawn } from "xstate";

// --- internal
import itemMachine from "../item.machine";
import { ItemActions as actions } from "./actions";
import services from "./services";
// --- utils
import {
  get,
  set,
  map,
  reduce,
  defaultsDeep,
  uniqueId,
  // compact,
  // pick,
  // isArray,
} from "lodash-es";

// --- types
import type { IAddress } from "./types";
import type { JsonSchema, UISchemaElement } from "@jsonforms/core";

// --------------------------------------------------------

export const useSchema = ({
  // TODO: country,
  countries,
  regions,
  types,
  baseModel,
  // ---
  places,
  // }: AddressContext) => {
}: any) => {
  const schema = {
    type: "object",
    title: "Address Fields",
    required: ["address_1", "city", "country_id", "postcode", "type"],
    properties: {
      id: {
        type: ["string", "null"],
        title: "ID",
        description: "The AutoGenerated ID of this Address.",
        readOnly: true,
      },

      // ---

      place: {
        type: ["string", "null"],
        title: "Address",
        lookup: places?.search,
      },

      manualPlace: {
        type: ["boolean", "null"],
        title: "Can't see your address?",
        default: baseModel?.manualPlace,
        readOnly: true,
      },

      // ---
      address_1: {
        type: "string",
        title: "Address Line 1",
      },

      address_2: {
        type: ["string", "null"],
        title: "Address Line 2",
      },

      city: {
        type: "string",
        title: "City",
      },

      postcode: {
        type: "string",
        title: "Postcode",
      },

      region_id: {
        type: ["string", "null"],
        title: "Region",
        oneOf: !regions?.length
          ? undefined
          : map(regions, item => {
              return {
                const: item.id,
                title: item.name,
              };
            }),
      },

      // not sure this is ever used, but it is in the type definition
      state: {
        type: ["string", "null"],
        title: "State",
      },

      country_id: {
        type: "string",
        title: "Country",
        default: baseModel?.country_id,
        oneOf: !countries?.length
          ? undefined
          : map(countries, item => {
              return {
                const: item.id,
                title: item.name,
              };
            }),
      },

      // ---

      name: {
        type: ["string", "null"],
        title: "Name",
        default: baseModel?.name,
      },
      // ---

      default: {
        type: ["boolean", "null"],
        title: "Make this the default address?",
      },

      type: {
        type: "number",
        title: "Address Type",
        default: baseModel?.type,
        oneOf: !types?.length
          ? undefined
          : map(types, item => {
              return {
                const: item.key,
                title: item.value,
              };
            }),
      },
    },
  };

  // if (id) {
  //   schema.required.push("name");
  //   schema.required.push("type");
  // }

  return schema as JsonSchema;
};

export const useUischema = () => {
  const schema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/name",
        options: {
          focus: true,
          autocomplete: "off",
          placeholder: "My home address, etc...",
        },
        rule: {
          effect: "SHOW",
          condition: {
            scope: "#",
            schema: {
              required: ["id"],
            },
          },
        },
      },

      // ---
      {
        type: "Control",
        scope: "#/properties/place",
        options: {
          autocomplete: "off",
          placeholder: "Search for address ...",
          items: [
            {
              label: "Enter manually",
              value: "manual",
              as: "button",
              variant: "link",
              size: "sm",
              persist: true,
            },
          ],
        },
        rule: {
          effect: "HIDE",
          condition: {
            scope: "#",
            schema: {
              anyOf: [
                { required: ["id"] },
                {
                  required: ["place"],
                  properties: { place: { const: "manual" } },
                },
              ],
            },
          },
        },
      },

      // ---
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/address_1",
            label: "Address", // ensure we  show the title for BOTH address fields
            options: {
              focus: true,
              autocomplete: "address-line1",
              placeholder: "Address first line...",
            },
          },
          {
            type: "Control",
            scope: "#/properties/address_2",
            label: "", // ensure we DON'T show the title
            options: {
              autocomplete: "address-line2",
              placeholder: "Address second line...",
              class: "-mt-8",
            },
          },

          // ---
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/city",
                options: {
                  autocomplete: "address-level2",
                  placeholder: "City...",
                },
              },
              {
                type: "Control",
                scope: "#/properties/postcode",
                options: {
                  autocomplete: "postal-code",
                  placeholder: "Postcode...",
                },
              },
            ],
          },
          // ---
          {
            type: "Control",
            scope: "#/properties/region_id",
            options: {
              autocomplete: "address-level1",
              placeholder: "Please select a Region...",
            },
          },
          {
            type: "Control",
            scope: "#/properties/country_id",
            options: {
              autocomplete: "country",
              placeholder: "Please select a Country...",
            },
          },
        ],
        rule: {
          effect: "SHOW",
          condition: {
            scope: "#/properties/manualPlace",
            schema: { const: true },
          },
        },
      },

      // ---
      // We dont ever show this field as it is set by an action
      // {
      //   type: "Control",
      //   scope: "#/properties/default",
      //   options: {
      //     // toggle: true
      //   }
      // }
    ],
  };

  return schema as UISchemaElement;
};

export const useModelParser = (
  schema: JsonSchema,
  values: IAddress,
  baseModel?: IAddress
) => {
  const model = reduce(
    schema.properties,
    (result, field, key) => {
      const value =
        field?.const || get(values, key, field?.default || get(baseModel, key));
      set(result, key, value);
      return result;
    },
    {}
  );

  return defaultsDeep(model, values) as IAddress;
};

// --------------------------------------------------------

export const spawnItem = (model?: IAddress) => {
  try {
    const name = get(model, "id", uniqueId("item_"));
    return spawn(
      itemMachine
        // @ts-ignore
        .withConfig({
          actions,
          services,
        })
        .withContext({ model }),
      {
        name,
        sync: true,
      }
    );
  } catch (err) {
    console.error("AddressListings", "spawnItem", { model });
  }
};
