export * from "./modules";
export * as utils from "./utils";

import { inspect } from "@xstate/inspect";

// --------------------------------------------------------

const queryParams = new URLSearchParams(window.location.search);
// @ts-ignore
const debugging = import.meta.env.DEV || queryParams.has("debug");

// --------------------------------------------------------

export function test() {
  return "Upmind Headless";
}

// --------------------------------------------------------

if (debugging)
  inspect({
    // url: "https://stately.ai/registry/editor/inspect",
    // url: "https://statecharts.io/inspect",
    // url: "https://stately.ai/viz?inspect", // (default)
    iframe: false,
  });
