import { cva } from "class-variance-authority";
// -----------------------------------------------------------------------------

export default {
  button: {
    root: cva("", {}),
  },
  badge: {
    root: cva("rounded-md"),
  },
  form: {},
};
