// --- external
import { createMachine, assign } from "xstate";

// --- internal

// --- utils
import { find, forEach, isEmpty, every, isString } from "lodash-es";

// - --types
import type { ClientListingsContext, ClientListingsEvents } from "./types";

// --------------------------------------------------------

export default createMachine(
  {
    tsTypes: {} as import("./listings.machine.typegen").Typegen0,
    id: "clientListingsManager",
    predictableActionArguments: true,
    initial: "subscribing",
    context: {
      raw: [], // spawned actors
      items: [], // filtered actors
      filters: undefined,
      selected: undefined,
      // ---
      error: undefined,
    },
    states: {
      // Subscribe to changes in auth and listen for a valid Authenticated client,
      // we will also wait for a session before we can continue
      subscribing: {
        invoke: {
          id: "authCallback",
          src: "authSubscription",
        },
        on: {
          SESSION: { target: "checking" },
        },
      },

      checking: {
        invoke: {
          src: "isAuthenticated",
          onDone: { target: "available" },
          onError: { target: "unavailable" },
        },
      },

      unavailable: {
        on: {
          AUTHENTICATED: { target: "available" },
        },
      },

      available: {
        initial: "loading",
        states: {
          loading: {
            id: "loading",
            entry: ["clearError", "clearItems"],
            invoke: {
              src: "load",
              onDone: [
                {
                  target: "processing",
                  actions: ["setItems", "resetFiltered", "setInitial"],
                  cond: (_context, { data }) => data,
                },
                {
                  target: "idle",
                  actions: ["setItems", "resetFiltered"],
                },
              ],
              onError: {
                target: "#error",
                actions: ["setError", "clearSelected"],
              },
            },
          },
          idle: {
            always: [{ target: "empty", cond: "hasNoItems" }],
          },

          processing: {
            always: [{ target: "idle", cond: "isNotProcessing" }],
          },
          empty: {
            always: [{ target: "idle", cond: "hasItems" }],
          },

          filtering: {
            invoke: {
              src: "filter",
              onDone: {
                target: "filtered",
                actions: ["setFiltered"],
              },
              onError: {
                target: "filtered",
                actions: ["resetFiltered"],
              },
            },
          },
          filtered: {
            initial: "empty",
            states: {
              empty: {
                always: [
                  {
                    target: "available",
                    cond: "hasFilteredItems",
                  },
                ],
              },
              available: {
                always: [
                  {
                    target: "empty",
                    cond: "hasNoFilteredItems",
                  },
                ],
              },
            },
          },
          editing: {},
        },
        on: {
          REFRESH: {
            target: "available",
            actions: ["setInitial"],
          },

          SELECT: {
            actions: ["setSelected"],
          },

          FILTER: [{ target: "available.filtering", actions: ["setFilters"] }],

          ADD: {
            target: "available.editing",
            actions: ["add"],
          },

          EDIT: {
            target: "available.editing",
            actions: ["setSelected"],
          },
        },
      },

      error: { id: "error" },
      complete: {
        type: "final",
      },
    },
    on: {
      STOP: {
        target: "complete",
      },

      UNAUTHENTICATED: {
        target: "subscribing",
        actions: ["clearError", "clearItems"],
      },
    },
  },
  {
    actions: {
      add: assign({
        //  should be provided withConfig
      }),

      setItems: assign({
        //  should be provided withConfig
      }),

      resetFiltered: assign({
        items: ({ raw }, _event) => raw,
        filters: undefined,
      }),

      setFiltered: assign({
        // @ts-ignore
        items: (_context, { data }) => data,
      }),

      setFilters: assign({
        filters: (_context, { data }) => data,
      }),
      // --------------------------------------------

      clearItems: assign({
        raw: ({ raw }, _event) => {
          forEach(
            raw,
            (item: any) => !item?.state?.done && item?.stop && item?.stop()
          );
          return [];
        },
        items: [],
        selected: undefined,
        filters: undefined,
      }),

      // @ts-ignore
      setInitial: assign({
        initial: (
          { raw, initial }: ClientListingsContext,
          { data }: ClientListingsEvents
        ) => {
          if (isString(data) && !isEmpty(data)) return data; // if weve explicitly been given an id, use it. eg when we add a new item and its not yet in the raw list
          // otherwise use our existing initial value or the default
          return initial || find(raw, "state.context.model.default")?.id;
        },
        selected: (
          { raw, initial }: ClientListingsContext,
          _event: ClientListingsEvents
        ) => {
          initial ??= find(raw, "state.context.model.default")?.id;
          return find(raw, ["id", initial]); //|| find(raw, "state.context.model.default")
        },
      }),

      // @ts-ignore
      setSelected: assign({
        initial: ({ selected, initial }) => selected?.id || initial,
        // filters: undefined,
        // items: ({ raw }, _event) => raw,
        selected: (
          { raw }: ClientListingsContext,
          { data }: ClientListingsEvents
        ) => find(raw, ["id", data]), // || find(raw, "state.context.model.default")
      }),

      clearSelected: assign({
        // @ts-ignore
        initial: undefined,
        filters: undefined,
        items: ({ raw }, _event) => raw,
        selected: undefined,
      }),

      // ---
      setError: assign({
        error: (_context, { data }: any) => {
          const error = data?.error;
          return error || data;
        },
      }),

      // @ts-ignore
      clearError: assign({ error: null }),
    },
    guards: {
      isNotProcessing: ({ raw }) => {
        return every(raw, (item: any) => !item?.state?.matches("loading"));
      },
      hasItems: ({ raw }) => !isEmpty(raw),
      hasNoItems: ({ raw }) => isEmpty(raw),
      hasFilteredItems: ({ items }) => !isEmpty(items),
      hasNoFilteredItems: ({ items }) => isEmpty(items),
    },
  }
);
