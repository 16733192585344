// --- external
import { spawn } from "xstate";

// --- internal
import itemMachine from "../item.machine";
import { ItemActions as actions } from "./actions";
import services from "./services";

// --- utils
import { get, uniqueId } from "lodash-es";
export { useModelParser } from "../../../utils";

// --- types
import type { IEmail } from "./types";
import type { JsonSchema, UISchemaElement } from "@jsonforms/core";

// --------------------------------------------------------

export const useSchema = () => {
  const schema = {
    type: "object",
    title: "Address Fields",
    required: ["email"],
    properties: {
      id: {
        type: ["string", "null"],
        title: "ID",
        description: "The AutoGenerated ID of this Email.",
        readOnly: true,
      },

      // ---
      type: {
        type: "number",
        const: 1,
      },

      email: {
        type: ["string", "null"],
        format: "email",
        title: "Email",
      },

      // ---

      default: {
        type: ["boolean", "null"],
        title: "Make this the default email?",
      },
    },
  };

  return schema as JsonSchema;
};

export const useUischema = () => {
  const schema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/email",
        options: {
          focus: true,
          autocomplete: "email",
          placeholder: "name@email.com",
        },
      },

      // ---
      // We dont ever show this field as it is set by an action
      // {
      //   type: "Control",
      //   scope: "#/properties/default",
      //   options: {
      //     // toggle: true
      //   }
      // }
    ],
  };

  return schema as UISchemaElement;
};

// --------------------------------------------------------

export const spawnItem = (model?: IEmail) => {
  try {
    const name = get(model, "id", uniqueId("item_"));
    return spawn(
      itemMachine
        // @ts-ignore
        .withConfig({
          actions,
          services,
        })
        .withContext({ model }),
      {
        name,
        sync: true,
      }
    );
  } catch (err) {
    console.error("EmailListings", "spawnItem", { model });
  }
};
