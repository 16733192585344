// --- internal
import { useApi } from "../../api";
import { useBrand, BrandConfigKeys } from "../../brand";

// --- utils
import { compact, includes, isEmpty, get } from "lodash-es";

// --- types
// import type { UploadEvent, UploadContext } from "./types";
import type { UploadEvent } from "./types";
import { ImageObjectTypes, ImageUploadTypes } from "./types";

// --------------------------------------------------------
// HELPERS

const fieldPath = (field: any) => {
  let path;

  switch (field.field_type) {
    case ImageObjectTypes.CLIENT:
      path = `clients/${field.field_id}/images`;
      break;
    case ImageObjectTypes.USER:
      path = `users/${field.field_id}/images`;
      break;
    case ImageObjectTypes.PRODUCT:
      path = `products/${field.field_id}/images`;
      break;
    case ImageObjectTypes.PRODUCT_CATEGORY:
      path = `products_categories/${field.field_id}/images`;
      break;
    case ImageObjectTypes.BRAND:
      path = `brands/${field.field_id}/images`;
      break;
    case ImageObjectTypes.BRAND_FAVICON:
      path = `brands/${field.field_id}/images/favicon`;
      break;
    case ImageObjectTypes.BRAND_EMAIL_LOGO:
      path = `brands/${field.field_id}/images/email_logo`;
      break;
    case ImageObjectTypes.CLIENT_CUSTOM_FIELD:
      path = field.field_id
        ? `clients/fields/${field.field_id}/image`
        : `clients/fields/images`;
      break;
    default:
      path = "images";
      break;
  }
  const append = field.field_is_default ? "default" : "";
  return compact([path, append]).join("/");
};

// --------------------------------------------------------
// SERVICE METHODS
// Invoked by machines, providing context and event data

// TODO: async function getImage({ field }: UploadContext, { data }: UploadEvent) {
async function getImage({ field }: any, { data }: UploadEvent) {
  // if we have a hash, we can skip the request
  if (data?.hash) {
    return Promise.resolve({ ...field, value: data.hash });
  }

  if (!field?.field_type && !data.hash)
    return Promise.reject("No field type or hash provided");

  const { get, useUrl, useTime } = useApi();

  // const path = `${fieldPath({ field_type: field.field_type })}/${data.hash}`;
  const path = `images/${data.hash}`;

  return get({
    url: useUrl(path),
    withAccessToken: true,
    useCache: true,
    maxAge: useTime()?.DAY,
  }).then(({ data }: any) => data);
}

async function check(_context: any, { data }: any) {
  let isValid = true;
  let error: any = null;

  const { getConfig } = useBrand();

  const fileTypes = await getConfig(
    BrandConfigKeys.ALLOWED_UPLOAD_FILE_TYPES
  ).then(response => {
    const types =
      get(response, BrandConfigKeys.ALLOWED_UPLOAD_FILE_TYPES) ||
      ImageUploadTypes;
    return types;
  });

  if (!isEmpty(fileTypes) && !includes(fileTypes, data.type)) {
    isValid = false;
    error = "Invalid file fileType";
  }

  // if (file.size > 1000000) {
  //   isValid = false;
  //   message = "File size is too big";
  // }

  // if (file.size < 100000) {
  //   isValid = false;
  //   message = "File size is too small";
  // }

  // if (file.width < 100 || file.height < 100) {
  //   isValid = false;
  //   message = "Image dimensions are too small";
  // }

  // if (file.width > 1000 || file.height > 1000) {
  //   isValid = false;
  //   message = "Image dimensions are too big";
  // }

  return new Promise((resolve, reject) => {
    if (isValid) {
      resolve(data);
    } else {
      reject(error);
    }
  });
}

async function upload({ field, request }: any, _event: any) {
  const { post, useUrl } = useApi();
  const path = fieldPath(field);
  return post({
    url: useUrl(path),
    data: request,
    withAccessToken: true,
  }).then(({ data }: any) => data);
}

// --------------------------------------------------------
// EXPORTS

export default {
  getImage,
  check,
  upload,
};
